<template>
<div>
  Transfer Index
</div>
</template>

<script>
export default {
  name: "TransferIndex",
}
</script>

<style scoped>

</style>